p {
  font-size: 14px;
  color: #262626;
}
.p-tag-home {
  font-size: 26px;
  text-align: justify;
}
.align-center {
  text-align: center;
}
.text {
  font-size: 16px;
  min-height: 140px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 213px;
}
.scroll {
  height: 50px;
  width: 50px;
}
.home {
  position: fixed;
  float: right;
  top: 50%;
  right: 2%;
  height: 40px;
  width: 40px;
}
.padding {
  padding-top: 54px;
}
.zindex {
  z-index: 4;
}
.navbar {
  position: fixed;
  width: 100%;
  z-index: 4;
}
.home-page {
  position: fixed;
  float: right;
  top: 55%;
  color: orange;
  right: 2%;
}
.font-head {
  font-family: serif;
  font-size: 71px;
  letter-spacing: 8px;
}
.landing-page {
  padding: 0px 15% 0px 15%;
}
.d-none {
  display: block !important;
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.status {
  color: white;
  margin-left: 10%;
}
.status:hover {
  color: #ffff !important;
}
.testimonial-slider {
  height: 200px;
}
.slider-image {
  height: 133px;
  background-image: linear-gradient(to right, rgb(255 222 88), rgb(252 144 75));
}
.slider-image1 {
  height: 133px;

  background-image: linear-gradient(to right, rgb(252 145 76), rgb(255 222 89));
}
.slider-image2 {
  height: 133px;
  background-image: linear-gradient(to right, rgb(255 222 88), rgb(252 144 75));
}
.logo-image {
  height: 80px;
  width: 84px;
  position: absolute;
  top: 0;
  left: 0;
}
.header {
  margin-left: 40px;
}
.main-heading {
  color: rgb(204, 18, 18);
  font-size: 26px;
}
.info {
  margin-right: 10px;
}
.underline {
  height: 04px;
  width: 4rem;
  background-color: #f76c2f;
  margin-bottom: 10px;
  margin-top: 10px;
}
.size-f {
  font-size: 16px;
}
.size-l {
  font-size: 18px;
  min-height: 161px;
}
.feedback-image {
  height: 100%;
  width: 100%;
}
.size-font {
  font-size: 16px;
  text-align: left;
}
.bg-orange {
  border: none;
  background-image: linear-gradient(to right, rgb(255 222 88), rgb(252 144 75));
}
.btn-clr {
  color: #424242;
}
strong {
  font-weight: 600 !important;
}
.card-background {
  text-align: center !important;
  background-color: transparent;
  border: none;
}
.text-orange {
  color: orange !important;
  text-decoration: none !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.font-content {
  font-size: 30px;
  font-weight: 100;
  color: gray;
  font-family: auto;
}
.bg-c-light {
  background-color: #f2f2f2;
}
.bg-info {
  background-image: linear-gradient(to right, rgb(255 222 88), rgb(252 144 75));
}
.footer a {
  color: #fff;
  text-decoration: none !important;
}
.footer a:hover {
  color: gray;
}
.feedback {
  margin-right: 10px;
}
.mobile-view {
  display: none;
}
.iso-logo {
  width: 100%;
  height: 58%;
}
.padding-right {
  padding-right: 10px;
}
@media screen and (max-width: 768px) {
  .mobile-view {
    display: block;
  }
  .height {
    height: 200px;
  }
  .ml {
    margin-left: 15%;
  }
}

@media screen and (max-width: 1274px) {
  .status {
    display: none;
  }
  .font-head {
    font-size: 50px;
  }
}
